// import

import type {ReactNode} from 'react';

import * as Modal from '~/modules/Modal';

// types

export type ErrorViewProps = {
  title: string;
  children?: ReactNode;
};

// export

export function ErrorView(props: ErrorViewProps) {
  const {title, children} = props;

  return (
    <Modal.RootAsRoute hideOverlay>
      <Modal.Content title={title} titleAs="h1">
        {children}
      </Modal.Content>
    </Modal.RootAsRoute>
  );
}
